import React from "react";
import { Link } from "react-router-dom";
import "../index.css";

const ProjectCard = ({ id, title, summary, languages }) => {
  return (
    <Link to={`/project/${id}`} className="card-link">
      <section className="card">
        <h2>
          {title}{" "}
          {languages.map((lang) => (
            <span key={lang} className="chip">
              {lang}
            </span>
          ))}
        </h2>
        <p>{summary}</p>
      </section>
    </Link>
  );
};

export default ProjectCard;
