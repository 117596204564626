import React from "react";
import { useParams, Link } from "react-router-dom";
import "../index.css"; // Make sure this path is correct

const ProjectDetail = ({ projects }) => {
  const { id } = useParams();
  const project = projects.find((p) => p.id.toString() === id);

  if (!project) {
    return <div className="project-not-found">Project not found.</div>;
  }

  const {
    title,
    summary,
    video,
    thoughts,
    features,
    technology,
    gallery,
    Pdf,
  } = project;

  return (
    <div className="project-detail-content">
      <div className="project-title-header">
        <Link to="/" className="back-arrow">
          ←
        </Link>
        <h1 className="project-detail-title">{title}</h1>
      </div>
      <div className="detail-line"></div>
      <p className="project-detail-summary">{summary}</p>
      <div className="project-detail-section">
        <h2 className="detail-section-title">Technology Stack</h2>
        <ul className="detail-tech-list">
          {technology.map((tech, index) => (
            <li key={index} className="detail-chip">
              {tech}
            </li>
          ))}
        </ul>
      </div>
      <div className="project-detail-section">
        <h2 className="detail-section-title">Key Features</h2>
        <ul className="detail-features-list">
          {features.map((feature, index) => (
            <li key={index} className="detail-chip">
              {feature}
            </li>
          ))}
        </ul>
      </div>
      {video && (
        <div className="project-detail-section">
          <h2 className="detail-section-title">Demo</h2>
          <iframe
            src={video}
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
            title="video"
            width={780}
            height={400}
          />
        </div>
      )}
      {Pdf && (
        <div>
          Link: <a href={Pdf}>SIEM.docx</a>
        </div>
      )}
      <footer className="detail-footer">
        <p>&copy; 2023 Salem</p>
      </footer>
    </div>
  );
};

export default ProjectDetail;
