import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./comp/HomePage";
import ProjectDetail from "./comp/ProjectDetail";
const projects = [
  {
    id: 1,
    title: "Garagat",
    summary:
      "A mobile application for trading car-parts reliably. I built it using Jetpack Compose, and my team built the website.",
    languages: ["Kotlin", "Firebase"],
    technology: [
      "Android Studio",
      "Firebase Auth",
      "Firebase Firestore",
      "Google Maps API",
    ],
    features: [
      "User authentication and profile management",
      "Real-time listings of car parts",
      "Chat system for buyer-seller communication",
      "Rating and review system",
      "Location-based search and filtering",
    ],
    video: "https://www.youtube.com/embed/mLlOyj_DIIs",
    thoughts:
      "This project enhanced my understanding of real-time databases and geolocation services.",
    gallery: [
      "https://example.com/images/garagat-1.jpg",
      "https://example.com/images/garagat-2.jpg",
    ],
  },
  {
    id: 2,
    title: "AES Manipulation Tool",
    summary:
      "A messaging website using AES encryption, I've built this to understand AES better.",
    languages: ["JavaScript", "CryptoJS"],
    technology: ["React", "AES library", "CLI"],
    features: [
      "Multipile profiles",
      "Encryption and decryption functionality",
      "Evesdropping",
      "Different POV through same channel",
    ],
    video: "https://www.youtube.com/embed/zQuyVQnpcD8",
    thoughts: null,
    gallery: [
      "https://example.com/images/aes-tool-1.jpg",
      "https://example.com/images/aes-tool-2.jpg",
    ],
  },
  {
    id: 3,
    title: "SIEM for Banking",
    summary:
      "A comprehensive study focused on the application of SIEM (Security Incident and Event Management) technology in the banking sector, with a specific focus on Qatar National Bank (QNB). The project involved in-depth research into the various components of SIEM systems, their impact on cybersecurity in banking, and recommendations for effective implementation.",
    languages: ["Research"],
    technology: [
      "SIEM Systems",
      "Cybersecurity",
      "Data Analysis",
      "AI and Machine Learning",
    ],
    features: [
      "Data collection",
      "Normalization",
      "Incident management",
      "Alerting techniques",
    ],
    video: null,
    Pdf: "https://docs.google.com/document/d/1RacoMwJ_doeYOQt9BCMYR315Dq7XGJyX/edit?usp=share_link&ouid=103825029811144176975&rtpof=true&sd=true",
    gallery: null,
    references: [
      "https://www.ibm.com/topics/siem",
      "https://www.ibm.com/products/qradar-siem",
      "https://assets.contentstack.io/v3/assets/blt36c2e63521272fdc/bltdde504f560f00642/622a372a737c7822c2881801/SANS_Benchmarking_Security_Information_Event_Management_(SIEM).pdf",
      "https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-61r2.pdf",
    ],
  },
  {
    id: 4,
    title: "DBMS Query Optimizer",
    summary:
      "A tool for optimizing SQL queries in database management systems, enhancing efficiency and speed.",
    languages: ["SQL", "JavaScript"],
    technology: ["MySQL", "Node.js", "Performance Analysis Tools"],
    features: [
      "Advanced SQL query parsing",
      "Query optimization techniques",
      "Execution plan analysis",
      "Database indexing and schema refinement",
    ],
    video: "https://www.youtube.com/embed/O3LkGXVoWb4",
    thoughts:
      "Developing this optimizer was an excellent exercise in understanding the inner workings of database engines.",
    gallery: [
      "https://example.com/images/dbms-query-optimizer-1.jpg",
      "https://example.com/images/dbms-query-optimizer-2.jpg",
    ],
  },
  {
    id: 5,
    title: "OpenGL Solar System",
    summary:
      "A 3D model of the solar system using OpenGL, showcasing realistic physics and appearances.",
    languages: ["C++", "GLSL"],
    technology: ["OpenGL", "GLUT", "GLM"],
    features: [
      "Accurate planetary orbits",
      "Realistic textures and lighting",
      "Camera controls for exploration",
      "Physics simulation for celestial movements",
    ],
    video: "https://www.youtube.com/embed/n-bDqPvjYdk",
    thoughts:
      "Creating a 3D simulation of the solar system pushed my graphical programming skills to new heights.",
    gallery: [
      "https://example.com/images/opengl-solar-system-1.jpg",
      "https://example.com/images/opengl-solar-system-2.jpg",
    ],
  },
  // Add more projects as needed
];

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage projects={projects} />} />
        <Route
          path="/project/:id"
          element={<ProjectDetail projects={projects} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
