import React from "react";
import ProjectCard from "./ProjectCard";
import "../index.css"; // Make sure the path to your CSS file is correct

const HomePage = ({ projects }) => {
  if (!projects) {
    return <div>Loading...</div>; // Or handle the lack of projects however you prefer
  }
  return (
    <div>
      <section>
        <h1>Salam!</h1>
        <div className="line"></div>
        <p>
          I'm Salem, a Qatari computer science graduate from QU. I've developed
          a diverse range of projects in various fields, which I've shared some
          of them below. My primary interests include mobile and web
          development, with a recent focus on cybersecurity.
        </p>
      </section>
      <section>
        <h1>Projects</h1>
        <div className="line"></div>
        {projects.map((project) => (
          <ProjectCard
            id={project.id}
            title={project.title}
            summary={project.summary}
            languages={project.languages}
          />
        ))}
      </section>
      <footer>
        <p>&copy; 2023 Salem</p>
      </footer>
    </div>
  );
};

export default HomePage;
